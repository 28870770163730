@import 'toolkit';

.c-vshcz-fail-snack {
  background: color(warn);
  // padding-right: 6px !important;

  .mat-simple-snackbar-action {
    color: rgba(255, 255, 255, 0.7);

    .mat-button {
      text-transform: uppercase !important;
    }
  }
}

.c-vshcz-success-snack {
  background: color(primary);
  // padding-right: 6px !important;

  .mat-simple-snackbar-action {
    color: rgba(255, 255, 255, 0.7);

    .mat-button {
      text-transform: uppercase !important;
    }
  }
}

.c-vshcz-info-snack {
  background: mat-color($mat-blue, 400);
  // padding-right: 6px !important;

  .mat-simple-snackbar-action {
    color: rgba(255, 255, 255, 0.7);

    .mat-button {
      text-transform: uppercase !important;
    }
  }
}
