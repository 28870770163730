.u-card--no-padding {
  padding: 0 !important;
}

.u-card--half-padding {
  padding: $bu / 2 !important;
}

.u-card--third-padding {
  padding: $bu / 3 !important;
}

.u-card--quarter-padding {
  padding: $bu / 4 !important;
}

.u-card--stroked {
  box-shadow: none !important;
  border: 1px solid mat-color($mat-grey, 300);
}

.u-card--no-shadow {
  box-shadow: none !important;
  border: none !important;
}