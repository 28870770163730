.c-or {
  position: relative;

  display: block;
  max-width: 100px;
  margin: 0 auto;

  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  font-weight: 500;

  &:after {
    content: '';

    position: absolute;
    top: 10px;
    left: 0;

    height: 1px;
    width: 100%;

    background: mat-color($mat-grey, 500);
    opacity: 0.2;
  }
}

.c-or__text {
  position: relative;
  z-index: 1;

  display: inline-block;
  padding: 5px;

  background: white;

  color: mat-color($mat-grey, 500);
}
